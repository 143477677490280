
.radio-group {
    display: flex;
    flex-wrap: wrap;

    .radio {
        flex-shrink: 0;
        padding-right: 20px;
    }

    &.column {
        flex-direction: column;
        flex-wrap: nowrap;

        .radio {
            padding-right: 0px;
        }
    }

    @media (max-width: 550px) {
        flex-direction: column;
        flex-wrap: nowrap;

        .radio {
            padding-right: 0px;
        }
    }
}
