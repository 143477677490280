
@use "~@stamhoofd/scss/base/variables.scss" as *;
@use "~@stamhoofd/scss/base/text-styles.scss" as *;

#home-view {
    .stamhoofd-footer {
        @extend .style-description;
        padding: 15px 0;
        padding-top: 30px;

        a {
            white-space: normal;
            text-overflow: initial;
            height: auto;
            line-height: 1.4;
        }

        strong {
            color: $color-primary-original;
            margin-left: 5px;
        }
    }
}

.split-login-view {
    padding-top: 40px;
    display: grid;
    width: 100%;
    grid-template-columns: minmax(300px, 350px) auto;
    gap: 100px;
    align-items: center;

    @media (max-width: 800px) {
        padding-top: 0;
        display: block;

        > aside {
            padding: 0 var(--st-horizontal-padding, 20px);
            padding-top: 20px;
        }
    }

    ol {
        list-style: none; 
        counter-reset: li;
        @extend .style-normal;
        padding-left: 30px;

        li {
            counter-increment: li;
            padding: 8px 0;
        }

        li::before {
            content: counter(li)"."; 
            @extend .style-normal;
            color: $color-primary;
            display: inline-block; 
            width: 30px;
            margin-left: -30px;;
        }
    }

    aside > h1 {
        @extend .style-title-2;
        padding-bottom: 20px;;
    }
}

.login-view {
    > h1 {
        @extend .style-title-1;
        padding-bottom: 20px;
    }

    > p {
        @extend .style-description;
        padding-bottom: 10px;
    }

    > .button-box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        > * {
            margin-top: 10px;;
        }
    }
}
