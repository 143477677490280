

.code-input {
    -webkit-touch-callout: none !important;

    > div {
        display: inline-flex;
        flex-direction: row;

        .input {
            margin: 0 3px;
            max-width: 38px;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            font-size: 20px;
            caret-color: transparent;
            text-transform: uppercase;
            -webkit-touch-callout: none !important;

            &:nth-child(3) {
                margin-right: 15px;
            }
        }

    }
}

